<template>
<div style="width:100%">
  <br>
  <span v-if="form_objs.items_escalamiento.length>=1"><b>ESCALAMIENTOS</b></span>
  <template v-for="(item, index) in form_objs.items_escalamiento">
    <div :key="index">
    <v-row no-gutters>
      <v-col cols="12" md="1" align-self="center" class="text-center">
        <v-avatar :color="colores.primario" size="30" class="white--text">{{ index + 1}}</v-avatar>
      </v-col>
      <v-col cols="12" md="5" align-self="center">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ item.area }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.correo }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              <uploadFilex :session="session" :id_escaladoa="item.id_usuario" :area_id="item.area_id" :index="index" :keyform="form_objs.keyform" :items_archivos="item.items_archivos" :urlroute="'upload_cargar_escalamiento'" :tipo="'ESCALAMIENTO'" :colores="colores" @refresh_archivos="refrescar_archivos"></uploadFilex>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="5">
        <v-textarea v-model="item.descripcion"  :color="colores.primario" label="DESCRIPCIÓN" rows="2"></v-textarea>
      </v-col>
      <v-col cols="12" md="1" align-self="center" class="text-center">
        <v-btn @click="eliminar(index)" icon :color="colores.red"><v-icon>mdi-delete</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    </div>
  </template>

</div>
</template>

<script>
// import uploadFilex from '@/components/widgets/uploadxComponent.vue'
export default {
  name: 'escalamientosComponent',
  components: {
    uploadFilex: () => import('@/components/widgets/uploadxComponent.vue')
  },
  props: ['colores', 'session', 'form_objs'],
  data: () => ({

  }),
  methods: {
    refrescar_archivos (index, data) {
      for (let i = 0; i < data.length; i++) {
        this.form_objs.items_escalamiento[index].items_archivos.push(data[i])
      }
    },
    eliminar (index) {
      this.form_objs.items_escalamiento.splice(index, 1)
    }

  }
}
</script>
